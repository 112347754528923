import React, { useRef } from "react";
import {
  graphql,
  useStaticQuery,
} from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components';
import ArrowRight from 'src/images/icons/arrow-right.svg'
import ArrowLeft from 'src/images/icons/arrow-left.svg'
import Slider from "react-slick";
import 'src/styles/slick.css'
import 'src/styles/slick-theme.css'
import 'src/styles/slick-theme-carousel.css'
import { useMediaQuery } from 'react-responsive'
import previewThemes from 'src/themes/previewThemes'

// responsive image queries for theme template images
export const imageQuery = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "templates" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const BlueArrowButton = ({ArrowClass, className, style, onClick}) => {
  const arrowWidth = 18
  const blueCircleStyle = {
    borderRadius: '50%',
    width: 44,
    height: 44,
    backgroundColor: '#335EEA',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  }
  return (
    <div style={{...style, ...blueCircleStyle}} onClick={onClick} className={className}>
      <ArrowClass width={arrowWidth} height={arrowWidth} fill='white'/>
    </div>
  )
}

const PrevButton = ({className, style, onClick}) => {
  return <BlueArrowButton ArrowClass={ArrowLeft} {...{className, style, onClick}}/>
}
const NextButton = ({className, style, onClick}) => {
  return <BlueArrowButton ArrowClass={ArrowRight} {...{className, style, onClick}}/>
}

const SelectTemplateButton = styled('button')`
  position: absolute;
  bottom: 80px;
`

const SlideHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  color: white;

  @media screen and (max-width: 1023px) {
     display: none;
  }
`

const SlideTitle = styled.div`
`

const SlideSubtitle = styled.p`
`

const SlideImage = styled(Img)`
  width: 90%;
  cursor: pointer;
`

// Slide assumes its theme image has the typical resume proportions:
// w = .70735 x h
// And by default assumes you want a fullscreen sort of experience:
// that in a typical landscape orientation, the image should take up about 85% of
// the height of the viewport and the width should be sized proportionally to that.
// and that in a mobile, portrait orientation, the image should take 85% of the
// viewport width, and the height should be sized proportionally to that.
// the values for width and height proportions can be overridden through props
// heightProportion and widthProportion
// Accepts an imageContainerStyle object with width and height properties
// to override defaults

// The slide accepts clicks on the button, but also the entire image. Clicks on the image are
// only accepted when the slide is finished swiping and is in the center. This is because
// we take advantage of some other slick slider gestures. when you click a slide that isn't in the center
// we let slick read that as a swipe of sorts to move that slide to center. so we don't want that to be a selection of the theme.
// also clicks are normally read during the swipe gesture of a slide, so we ignore those too.
const Slide = ({title, subtitle, image, id, imageData, index, onClick, slideStyleOverrides, heightProportion, widthProportion, imageContainerStyle, headerStyle}) => {

  // targeted proportion of viewport height that slide image should take
  const _heightProportion = heightProportion || 85
  // proportion of width to height
  const _widthProportion = _heightProportion * .70735

  // we assume landscape orientation where height is the more constrained dimension
  // and so define width and height in terms of viewport height
  const _imageContainerStyle = {
    width: `${_widthProportion}vh`,
    height: `${_heightProportion}vh`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...imageContainerStyle,
  }

  if (imageContainerStyle && imageContainerStyle.width) {
    _imageContainerStyle.width = imageContainerStyle.width
  }
  if (imageContainerStyle && imageContainerStyle.height) {
    _imageContainerStyle.height = imageContainerStyle.height
  }

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    ...slideStyleOverrides,
  }

  let titleStyle = {
    marginBottom: 0,
  }
  let subtitleStyle = {}

  // this makes it so the subtitle element width matches the image container width
  // otherwise, longer text stretches out the parent element and messes up the slide width
  // instead of making slide match the image container

  if (imageContainerStyle?.width) {
    subtitleStyle.width = imageContainerStyle.width
  }

  if (slideStyleOverrides && slideStyleOverrides.titleStyle) {
    titleStyle = {
      ...titleStyle,
      ...slideStyleOverrides.titleStyle,
    }
  }
  if (slideStyleOverrides && slideStyleOverrides.subtitleStyle) {
    subtitleStyle = {
      ...subtitleStyle,
      ...slideStyleOverrides.subtitleStyle,
    }
  }

  const onClickImage = () => {
    onClick({id, index, source: 'image'})
  }
  const onClickButton = () => {
    onClick({id, index, source: 'button'})
  }

  return (
    <div style={containerStyle}>
      <SlideHeader style={headerStyle}>
        <SlideTitle
          className='font-weight-bold h5'
          style={titleStyle}
        >
          {title}
        </SlideTitle>
        <SlideSubtitle
          className='font-size-xs text-center'
          style={subtitleStyle}
        >
          {subtitle}
        </SlideSubtitle>
      </SlideHeader>
      <div
        style={_imageContainerStyle}
        onClick={onClickImage}
      >
        <SlideImage
          fluid={imageData}
          alt={`${title} theme resume template`}
        />
      </div>
      <SelectTemplateButton
        className="btn btn-primary carousel-button"
        onClick={onClickButton}
      >
        Use This Template
      </SelectTemplateButton>
    </div>
    )
}

// slideStyleOverrides: {
//   titleStyle,
//   subtitleStyle,
// }
// for carousel settings: https://react-slick.neostack.com/docs/api/
const ThemeCarousel = ({ onClickSlide, className, slideStyleOverrides, style, heightProportion, widthProportion, imageContainerStyle, carouselSettings, headerStyle,}) => {

  // responsive images:
  const imageData = useStaticQuery(imageQuery)

  // create map
  // image filename: image data
  // to pull into ordered preview themes
  const imageFilenameToImageDataMap = {}
  imageData.allFile.edges.forEach(image => {
    const node = image.node
    imageFilenameToImageDataMap[node.base] = node.childImageSharp.fluid
  })

  // these ref properties and before/after change hooks are used to ignore 'clicks' on the slide image when
  // the slide is being swiped and also when the user clicks a slide that isn't in the center. if user clicks on a slide
  // that's not in center, we let slick use that as a gesture to move that slide to center. see comments on
  // Slide class for more info.

  // index of the current slide (one in center)
  const currentSlideRef = useRef(0)
  // is the slider currently in the middle of a sliding / swiping gesture
  const isSlidingRef = useRef(false)

  const beforeChangeSlide = (current, next) => {
    isSlidingRef.current = true
  }
  const afterChangeSlide = (current) => {
    isSlidingRef.current = false
    currentSlideRef.current = current
  }

  const _onClickSlide = ({id, index, source}) => {
    // disable false clicks coming from dragging gestures
    if (isSlidingRef.current) {
      return
    }
    // disable false clicks on slides that are not current slide. those clicks are meant to move that slide to center.
    if (index !== currentSlideRef.current) {
      return
    }
    onClickSlide({id, index, source})
  }

  const slideData = previewThemes.map((theme) => ({
    ...theme,
    id: theme.key,
    title: theme.name,
    subtitle: theme.description,
    imageData: imageFilenameToImageDataMap[theme.imageFilename]
  }))

  const settings = {
      centerMode: true,
      infinite: true,
      dots: true,
      prevArrow: <PrevButton/>,
      nextArrow: <NextButton/>,

      // allows control over slide dimensions, otherwise slick tries to figure them out for you
      variableWidth: true,
      variableHeight: true,

      slidesToShow: 3,
      dotsClass: 'slick-dots slick-dots-light',

      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            arrows: false,
            slidesToShow: 1,
          }
        },
        {
          breakpoint: 576,
          settings: {
            arrows: false,
          }
        },
      ],
      beforeChange: beforeChangeSlide,
      afterChange: afterChangeSlide,
      speed: 200,
      ...carouselSettings,
    };
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    ...style,
  }
  // paging dots for slick slider are
  // position: absolute;
  // bottom: -25px;
  // so they stick out the bottom which can collide with other elements, like
  // the page footer
  // this spacer prevents that
  const dotsSpacerStyle = {
    width: '100%',
    height: 25,
  }
  const _className = `${className || ''} theme-carousel`
  return (
    <div style={containerStyle} className={_className}>
      <Slider {...settings}>
        {
          slideData.map((data, index) => <Slide {...data} onClick={_onClickSlide} {...{index, slideStyleOverrides, heightProportion, widthProportion, imageContainerStyle, carouselSettings, headerStyle}}/>)
        }
      </Slider>
      <div style={dotsSpacerStyle}/>
    </div>
    )
}

export default ThemeCarousel
