import React, { useState, useEffect, useRef } from "react";
import { navigate } from "gatsby"
import styled from 'styled-components';
import colors from 'src/constants/colors'
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import { identify, trackPage, trackEvent, getPropertiesFromLocation} from 'src/utilities/analytics'
import { createBetaUserSubscription } from 'src/utilities/http-requests'
import ThemeCarousel from 'src/components/ThemeCarousel'
import LoginModal from 'src/components/LoginModal'
import getFirebase from 'src/utilities/firebase'
import { createResume } from 'src/utilities/firestore'

// deprecated in favor of /resume-templates


const pageName = 'Templates'

const determineIfBeta = (location) => {
  const searchParams = new URLSearchParams(location.search)
  return searchParams.get('isBeta') == 'true'
}

// Guide Header Step

const GuideHeaderStepContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const GuideHeaderStepNumber = styled.div(props => ({
  borderRadius: '50%',
  backgroundColor: props.backgroundColor,
  color: props.color,
  width: 28,
  height: 28,
  fontSize: 16,
  fontWeight: 600,
  marginRight: 12,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const GuideHeaderStepText = styled.div(props => ({
  color: props.color,
}))

const GuideHeaderStepLine = styled.div({
  width: 32,
  height: 1,
  backgroundColor: colors.gray500,
  margin: '0px 8px',
})

const GuideHeaderStep = ({number, text, shouldHideLine, shouldBeGrayedOut}) => {
  const numberColor = shouldBeGrayedOut ? colors.gray700 : colors.white
  const numberBackgroundColor = shouldBeGrayedOut ? colors.gray500 : colors.primary
  const textColor = shouldBeGrayedOut ? colors.gray700 : colors.black
  return (
    <GuideHeaderStepContainer>
      <GuideHeaderStepNumber
        backgroundColor={numberBackgroundColor}
        color={numberColor}
      >
        {number}
      </GuideHeaderStepNumber>
      <GuideHeaderStepText
        color={textColor}
      >
        {text}
      </GuideHeaderStepText>
      {
        !shouldHideLine &&
        <GuideHeaderStepLine/>
      }
    </GuideHeaderStepContainer>
    )
}

const GuideHeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '30px 10px',
  cursor: 'default',
})

const GuideHeader = () => {
  return (
    <GuideHeaderContainer>
      <GuideHeaderStep
        number={1}
        text="Choose Template"
        shouldBeGrayedOut={false}
      />
      <GuideHeaderStep
        number={2}
        text="Enter Your Details"
        shouldBeGrayedOut={true}
      />
      <GuideHeaderStep
        number={3}
        text="Download Resume"
        shouldHideLine={true}
        shouldBeGrayedOut={true}
      />
    </GuideHeaderContainer>
    )
}

const Templates = ({location}) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false)

  const isBeta = determineIfBeta(location)

  // mutable references to most up to date values for selected theme and current user
  const selectedThemeRef = useRef()
  const currentUserRef = useRef()

  const firebase = getFirebase();
  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      currentUserRef.current = user
      attemptCreateResume()
      identify(user)
    });
    }, [firebase]);

  const onRequestClose = () => {
    setLoginModalIsOpen(false)
  }
  const signInSuccessWithAuthResult = () => {
    // have logic here to handle navigation after sign in
    // so override default login modal signin success redirect with no-op
    return false
  }
  const attemptCreateResume = () => {
    // if there is current user and selected theme
    // create new resume with theme then navigate to that resume's page
    // the control flow can get complicated since i need to
    // get most up to date values from within the firebase auth state change callback
    // this function with refs allows it to always check most recent values
    // and attempt to create resume once both
    // - theme is selected
    // - user is logged in
    if (!selectedThemeRef.current || !currentUserRef.current) {
      return
    }

    let betaUserSubscriptionPromise = Promise.resolve(null)
    if (isBeta && currentUserRef.current) {
      betaUserSubscriptionPromise = createBetaUserSubscription({userUID: currentUserRef.current.uid})
                                      .then(() => console.log('created beta user subscription'))
    }

    const resumeInfo = {
      metadata: {
        theme: selectedThemeRef.current
      }
    }
    const createResumePromise = createResume({
      ownerUID: currentUserRef.current.uid,
      resumeInfo
    })

    Promise.all([betaUserSubscriptionPromise, createResumePromise])
    .then(([empty, docRef]) => {
      navigate(`/resumes/${docRef.id}`)
    })
    .catch(error => console.error("Error creating resume: ", error))
  }

  const headerStyle = {
    alignSelf: 'center',
    marginTop: '1rem',
    cursor: 'default',
  }

  const subheaderStyle = {
    ...headerStyle,
    marginTop: 0,
  }

  useEffect(() => {
    const urlProperties = getPropertiesFromLocation(location)
    trackPage(
      pageName,
      {
        ...urlProperties,
      }
    )
  }, [])

  // when you select a slide
  // you can already be logged in or not
  // if you are logged in
  // select a theme, create resume, and continue
  // if you are not logged in
  // force either managed login or anonymous
  // then create resume and continue
  // if is beta, create beta user subcription

  const onClickSlide = ({id, source}) => {
    selectedThemeRef.current = id
    if (!currentUser) {
      setLoginModalIsOpen(true)
    }
    attemptCreateResume()
    trackEvent('clicked_select_template', {
      page: pageName,
      template_id: id,
      source,
    })
  }

  return (
    <Layout className="bg-gray-300">
      <SEO title={pageName}/>
      <AnalyticsHelmet/>
      <LoginModal isOpen={loginModalIsOpen} shouldAllowSkipLogin={true} {...{ firebase, onRequestClose, signInSuccessWithAuthResult }}/>
      <GuideHeader/>
      <h3
        style={subheaderStyle}
        className="text-secondary font-weight-bold"
      >
        Recruiter recommended
      </h3>
      <ThemeCarousel {...{onClickSlide}}/>
    </Layout>
  )
}

export default Templates
