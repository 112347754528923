export const primary = '#335EEA'

export const light = '#F9FBFD'

export const gray = '#869AB8'
export const gray300 = '#D9E2EF'
export const gray500 = '#bcc4cf' // not from landkit
export const gray600 = '#9ba2ab' // not from landkit
export const gray700 = '#7a8087' // not from landkit

export const gray800 = '#384C74'

export const white = '#FFFFFF'

export const black = '#161C2D' // landkit default color for text, body, buttons, etc

export default {
  primary,
  light,
  gray,
  gray300,
  gray500,
  gray600,
  gray700,
  gray800,
  black,
  white,
}
